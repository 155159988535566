import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls'

export default (total_sumary) ->
  request_swal = Swal.fire {
    confirmButtonText: 'Aplicar'
    cancelButtonText: 'Cancelar'
    showCancelButton: true
    allowOutsideClick: false
    title: 'Ingrese el código promocional'
    input: 'text'
    validationMessage: 'Ingresa un correo válido'
    inputAttributes:
      autocapitalize: 'off'
    customClass:
      confirmButton: 'uk-button blue-button uk-margin-right'
      cancelButton: 'uk-button black-button'
    buttonsStyling: false
  }
  request_swal.then ({value}) =>
    if value?
      AxiosRequest('/promotional_code', {code: value, total_worth: total_sumary}, 'post').then ({data}) =>
        Swal.fire(data.message)
        @discount = data.discount_result
        ls = new SecureLS()
        ls.set 'discount', @discount
