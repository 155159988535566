import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls'

export default (index, uid = 'joker', item) ->
    ls = new SecureLS();
    actual_cart = ls.get 'cart'

    # buscamos el carrito al que sele va hacer el proceso
    cart_filter = actual_cart[uid].filter((element) => (element.idproduct == item.idproduct))
    for cart_items in cart_filter
      if cart_items.quantity <= 1
        Swal.fire({
          title: 'Error',
          text: "las unidades no pueden ser menores o igual a zero",
          icon: 'error',
        })
      else
        cart_items.quantity--
    @cart_items = actual_cart
    ls.set 'cart', actual_cart
