import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls'

export default (index, uid, item) ->

  ls = new SecureLS();
  # traemos lo del ls
  actual_cart = ls.get 'cart'
  # traemos todos los datos del product selecionado
  cart_filter = actual_cart[uid].filter((element) => (element.idproduct == item.idproduct))
  for cart_items in cart_filter
    # si la cantidad del product selecionado es menos al limit, se pude sumar
    if cart_items.quantity < item.limit
      cart_items.quantity += 1
    else
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Número de unidades no válido (Unidades disponibles: #{item.limit})",
      })
    @cart_items = actual_cart
  ls.set 'cart', actual_cart
