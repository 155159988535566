import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import _ from 'lodash'

import SecureLS from 'secure-ls'
import AxiosRequest from "../utils/axios-request"

# FILTERS
import toCurrency from '../filters/to_currency'
# METHODS
import promotionalCode from '../methods/index_cart/promotional_code'
import addNewCant from '../methods/index_cart/add_new_cant'
import removeNewCant from '../methods/index_cart/remove_new_cant'
import removeCart from '../methods/index_cart/remove_cart'
import checkout from '../methods/index_cart/checkout'

Vue.use TurbolinksAdapter

document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'index_cart'
  return false unless el?

  new Vue {
    el
    filters: {toCurrency}
    methods: {
      promotionalCode,
      addNewCant,
      removeNewCant,
      removeCart,
      checkout
    }
    data: -> {

      cart_items: {},
      discount: {
        discount: 0,
        ptg: 0
      }
      waiting_cart: false
    }
    mounted: ->
      # Acceso a secure local storage
      ls = new SecureLS()
      cart = ls.get 'cart'
      disc = ls.get 'discount'
      @discount = disc if disc
      uid = document.getElementById('uid').dataset.uid
      final_cart = {}
      # Carrito que se llenó sin sesion
      final_cart['joker'] = cart['joker'] if cart['joker']
      # Comprobar si hay usuario
      if uid
        final_cart[uid] = cart[uid]
        # joker_cart
        query = $.get "/customers/#{uid}/shopping_carts/check.json"
        query.done (res) =>
          {cart_items, status, discount} = res
          switch status
            when 'waiting' then @waiting_cart = true
            when 'paid' then final_cart = {}
            when 'active','expired','declined', 'other_payment'
              # carrito ls
              local_cart = _.map final_cart[uid], (elem) => {elem...}
              # carrito bd
              remote_cart = _.map cart_items, (elem) => {elem...}
              # join in the carts
              final_cart[uid] = _.unionBy local_cart, remote_cart, (elem) => [elem.idproduct].join()
              # joker_cart
              if final_cart['joker']
                for item in final_cart[uid]
                  somecart = final_cart['joker'].some((item_joker) => (item_joker.idproduct == item.idproduct))
                  if somecart
                    index = final_cart['joker'].findIndex((item_joker) => (item_joker.idproduct == item.idproduct))
                    final_cart['joker'].splice index, 1
                    ls.set 'cart', final_cart
          @discount = discount if discount
        # final cart-joker
        if final_cart[uid] && final_cart['joker']
          for item in final_cart[uid]
            somecart = final_cart['joker'].some((item_joker) => (item_joker.idproduct == item.idproduct))
            if somecart
              index = final_cart['joker'].findIndex((item_joker) => (item_joker.idproduct == item.idproduct))
              final_cart['joker'].splice index, 1
              ls.set 'cart', final_cart
      @cart_items = final_cart
    computed: {
      total_sumary: ->
        {cart_items} = @
        return 0 if Object.keys(cart_items).length == 0
        uid = document.getElementById('uid').dataset.uid
        joined_cart = []
        if cart_items['joker']
          joined_cart.push(cart_items['joker']...) if cart_items['joker'].length > 0
        if @cart_items[uid]
          joined_cart.push(cart_items[uid]...) if uid
        acum = 0
        for item in joined_cart
          acum += item.price_cop * item.quantity
        acum
      total_items: ->
        {cart_items} = @
        return 0 if Object.keys(cart_items).length == 0
        uid = document.getElementById('uid').dataset.uid
        joined_cart = []
        if cart_items['joker']
          joined_cart.push(cart_items['joker']...) if cart_items['joker'].length > 0
        if @cart_items[uid]
          joined_cart.push(cart_items[uid]...) if uid
        acum = 0
        for item in joined_cart
          acum += 1
        acum
      total_sumary_usd: ->
        {cart_items} = @
        return 0 if Object.keys(cart_items).length == 0
        uid = document.getElementById('uid').dataset.uid
        joined_cart = []
        if cart_items['joker']
          joined_cart.push(cart_items['joker']...) if cart_items['joker'].length > 0
        if @cart_items[uid]
          joined_cart.push(cart_items[uid]...) if uid
        acum = 0
        for item in joined_cart
          acum += item.price_usd * item.quantity
        acum
    }
    destroyed: ->
      @cart_items = []
  }
