import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls'

export default (uid, trm) ->
  {cart_items, discount} = @
  return false if !cart_items

  parsedData = []
  parsedData.push(cart_items['joker']...) if cart_items['joker']
  parsedData.push(cart_items[uid]...) if cart_items[uid]
  authenticity_token = $('[name="csrf-token"]')[0].content
  final_data = {
    shopping_cart: {
      cart_items: parsedData,
      trm: trm,
      promotional_code: if discount.id then discount.id else ''
    },
    authenticity_token
  }
  AxiosRequest("/customers/#{uid}/shopping_carts/checkout", {final_data...}, 'post').then ({data}) =>
    window.location.replace(data.url)
